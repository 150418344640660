<template>
    <base-sticky-heading
        :title="$t('purchasesExpense.list.title')"
        class="min-h-[82px] items-center"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button
                    size="md"
                    variant="default"
                    icon="line-icons:general:plus"
                    @click="onCreateNewExpense"
                    v-if="$acl.can('create_expenses')"
                >
                    {{ $t('purchasesExpense.list.createNewExpense') }}
                </base-button>
            </div>
        </template>
        <data-grid
            table-borderless
            :data-source="entities.data"
            :columns="columns"
            :loading="loading"
            identify-field="id"
            @row-click="redirectToDetail"
        >
            <template #filter>
                <div class="flex w-full items-center justify-between gap-2">
                    <div class="grow" />
                    <div class="flex grow gap-3">
                        <purchases-expense-filter-search />
                    </div>
                </div>
            </template>

            <template #column-date="{ item }">
                <div class="text-sm font-normal text-gray-500">
                    {{ $filters.dateHumanFormat(item.date) }}
                </div>
            </template>

            <template #column-expense="{ item }">
                <div class="text-sm font-medium text-primary-600">
                    <template v-if="item.itemize">
                        {{ $t('general.itemized') }}
                    </template>
                    <template v-else>
                        {{ item.line_items[0]?.chart_of_account?.name }}
                    </template>
                </div>
            </template>

            <template #column-vendor="{ item }">
                <div class="text-sm font-normal text-gray-500">
                    {{ getContactName(item.contact) }}
                </div>
            </template>

            <template #column-status="{ item }">
                <base-badge
                    :label="$t('expenseStatus', { status: item.status })"
                    :variant="getStatusVariant(item.status)"
                />
            </template>

            <template #column-amount="{ item }">
                {{
                    $filters.symbolCurrency(
                        item.amount_gross_total,
                        item.currency
                    )
                }}
            </template>

            <template #column-actions>
                <base-icon
                    name="line-icons:files:paperclip"
                    variant="inherit"
                    size="sm"
                />
            </template>

            <template #footer>
                <grid-pagination
                    :total="entities.last_page"
                    :page="entities.current_page"
                />
            </template>
        </data-grid>
    </base-sticky-heading>
</template>

<script setup>
import { useGetExpense } from '@tenant/modules/tenant/purchases-expense/composables/use-expense'
import { getContactName } from '@tenant/utils/helper'
import { getStatusVariant } from '@tenant/modules/tenant/purchases-expense/utils/helper'

const { t } = useI18n()
const router = useRouter()

useHead({
    title: t('purchasesExpense.list.title'),
})

const { entities, loading } = useGetExpense()

const columns = [
    {
        property: 'date',
        label: t('purchasesExpense.list.columns.date'),
        sortable: true,
        sortDirection: 'desc',
    },
    {
        property: 'expense',
        label: t('purchasesExpense.list.columns.expenseAccount'),
    },
    {
        property: 'reference',
        label: t('purchasesExpense.list.columns.referenceNumber'),
    },
    {
        property: 'vendor',
        label: t('purchasesExpense.list.columns.vendorName'),
    },
    {
        property: 'status',
        label: t('purchasesExpense.list.columns.status'),
    },
    {
        property: 'amount',
        label: t('purchasesExpense.list.columns.amount'),
    },
    {
        property: 'actions',
        label: ' ',
    },
]

const onCreateNewExpense = () => {
    router.push({ name: 'purchases.expense.create' })
}

const redirectToDetail = (id) => {
    router.push({ name: 'purchases.expense.detail', params: { id } })
}
</script>
