const acl = useAcl()
const navigation = [
    {
        id: 'sas-purchases',
        path: 'bills',
        label: 'sidebar.purchases.label',
        icon: 'line-icons:finance-&-ecommerce:receipt',
        position: 39,
        permission: () =>
            acl.can('view_expenses') ||
            acl.can('view_bills') ||
            acl.can('view_vendor_credits'),
    },
    {
        id: 'sas-purchases-expense',
        path: 'purchases.expense',
        label: 'sidebar.purchases.expenses',
        parent: 'sas-purchases',
        position: 1,
        permission: 'view_expenses',
    },
]

export default navigation
