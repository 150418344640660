import { GUARD } from '@tenant/utils/constants'

const routes = [
    {
        name: 'purchases',
        path: '/purchases',
        redirect: {
            name: 'purchases.expense',
        },
        children: [
            {
                name: 'purchases.expense',
                path: 'expense',
                redirect: {
                    name: 'purchases.expense.list',
                },
                children: [
                    {
                        name: 'purchases.expense.list',
                        path: '',
                        component: () =>
                            import('./pages/purchases-expense-list.vue'),
                    },
                    {
                        name: 'purchases.expense.create',
                        path: 'create',
                        component: () =>
                            import('./pages/purchases-expense-create.vue'),
                        meta: {
                            permission: 'create_expenses',
                        },
                    },
                    {
                        name: 'purchases.expense.detail',
                        path: ':id',
                        component: () =>
                            import('./pages/purchases-expense-detail.vue'),
                    },
                    {
                        name: 'purchases.expense.edit',
                        path: 'edit/:id',
                        component: () =>
                            import('./pages/purchases-expense-edit.vue'),
                        meta: {
                            permission: 'update_expenses',
                        },
                    },
                ],
            },
        ],
    },
]

export default {
    routes,
    meta: {
        layout: 'dashboard-layout',
        guard: GUARD.TENANT,
        permission: 'view_expenses',
    },
}
