import { EXPENSE_STATUS } from '@tenant/modules/tenant/purchases-expense/utils/constants'

export const getStatusVariant = (billStatus) => {
    switch (billStatus) {
        case EXPENSE_STATUS.INVOICED:
            return 'success'
        case EXPENSE_STATUS.UN_BILLED:
            return 'warning'
        default:
            return 'gray'
    }
}
