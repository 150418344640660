import BigNumber from 'bignumber.js'

export default {
    purchasesExpense: {
        deleteSuccess: 'Expense has been deleted successfully',
        deleteModal: {
            title: 'Delete Expense',
            description: 'Are you sure you want to delete this expense?',
        },
        deleteFailureModal: {
            title: 'Deletion Failure',
            description:
                'Expense cannot be deleted because it is already paid.',
        },
        replaceCOATooltip: 'Recommended based on your history.',
        list: {
            title: 'Expenses',
            createNewExpense: 'Create new Expense',
            searchByReferenceNumber: 'Search by reference number',
            viewAll: 'View All',
            draft: 'Draft',
            open: 'Open',
            overdue: 'Overdue',
            partiallyPaid: 'Partially paid',
            paid: 'Paid',
            overdueBy: (item) => {
                const days = item.named('days')

                if (days === 0) {
                    return `Overdue today`
                }

                return `Overdue by ${days} days`
            },
            columns: {
                date: 'Date',
                expenseAccount: 'Expenses Account',
                referenceNumber: 'Reference Number',
                vendorName: 'Vendor Name',
                status: 'Status',
                amount: 'Amount',
            },
        },

        create: {
            title: 'New Expense',
            addSuccess: 'Expense successfully created!',
            backToSingleExpenseView: 'Back to single expense view',
            supplier: {
                label: 'Supplier *',
                placeholder: 'Select a Supplier',
            },
            customer: {
                label: 'Customer',
                placeholder: 'Select a customer',
            },
            expenseable: {
                label: 'Expenseable',
                hint: 'Toggle to classify expenses for client invoicing.',
            },
            reference: {
                label: 'Reference',
                placeholder: 'e.g. expense number',
            },
            date: {
                label: 'Document Date *',
            },
            paymentDate: {
                label: 'Payment Date *',
            },
            paymentAccount: {
                label: 'Payment Account *',
                placeholder: 'Select payment account',
            },
            expenseAccount: {
                label: 'Expense Account *',
                placeholder: 'Select expense account',
            },
            description: {
                label: 'Description *',
                placeholder: 'Enter description',
            },
            amount: {
                label: 'Amount *',
                placeholder: 'Enter amount',
            },
            tax: {
                label: 'Tax *',
                placeholder: 'Select a Tax',
            },

            uploadDescription: 'SVG, PNG, JPG or GIF (max. 800x400px)',
        },

        detail: {
            title: 'Expense Details',
            allExpenses: 'All Expenses',
            expenseAmount: 'Expense Amount',
            expenseAccount: 'Expense Account',
            account: 'Account',
            amount: 'Amount',
            expenseTotal: 'Expense Total',
            paidTo: 'Paid to',
            ref: 'Ref#',
            taxAmount: 'Tax Amount',
            taxExclusive: '{amount} ( Exclusive )',
            vat: ({ named }) => {
                const vat = named('vat')
                if (vat !== null && vat !== undefined) {
                    return `${new BigNumber(vat).toFixed()}% VAT`
                }

                return 'VAT'
            },
        },

        edit: {
            title: 'Expense Edit',
            editSuccess: 'Expense successfully edited!',
            invoiceWarning: {
                title: 'Important Note',
                description:
                    'An invoice has been created for this expense. Changes made here will not reflect on your invoice.',
            },
        },

        simpleUpload: {
            title: 'Delete file?',
            description: 'Do you want to delete this file from the Expense?',
        },

        form: {
            taxAmount: 'Tax Amount = {amount}',
        },
    },
}
