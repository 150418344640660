<template>
    <base-sticky-heading
        :title="$t('purchasesExpense.create.title')"
        class="min-h-[82px] items-center"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button variant="default" @click="onCancel">
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button variant="default" @click="onSaveAndNew">
                    {{ $t('general.saveAndNew') }}
                </base-button>
                <base-button
                    variant="primary"
                    icon="line-icons:general:save-01"
                    @click="onCreate"
                >
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>

        <div class="px-8 py-6">
            <purchases-expense-form-general
                can-upload
                @textract-success="onTextractSuccess"
            />

            <purchases-expense-line-items />

            <purchases-expense-total />
        </div>
    </base-sticky-heading>

    <transaction-book-close-modal
        :code="code"
        :date="parameters?.date"
        @modal-close="code = null"
    />
</template>

<script setup>
import { AMOUNT_TYPE } from '@tenant/utils/constants'
import { useQueryClient } from '@tanstack/vue-query'

const emitter = useEmitter()
const { t } = useI18n()
const router = useRouter()
const { successNotify } = useNotification()

const { uploadStorage } = useUploadStorage()
const { useApiCreate } = useApiFactory('expense')
const { execute: executeCreate, code, parameters } = useApiCreate()

useHead({
    title: t('purchasesExpense.create.title'),
})

const { handleSubmit, setErrors, setValues, setFieldValue } = useForm({
    initialValues: {
        itemize: false,
        tax_type: AMOUNT_TYPE.GROSS,
    },
})

onMounted(() => {
    emitter.on('reset-field-error', resetFieldError)
})

onMounted(() => {
    setValues({
        line_items: [
            {
                chart_of_account_id: null,
                description: null,
                amount: null,
                tax_rule_id: null,
            },
        ],
    })
})

const onCancel = () => {
    router.push({
        name: 'purchases.expense',
    })
}

const onCreate = async () => {
    try {
        await onSave()
        await router.push({
            name: 'purchases.expense.list',
        })
    } catch ({ errors }) {
        setErrors(errors)
    }
}

const onSaveAndNew = async () => {
    try {
        await onSave()
        emitter.emit('set-reload')
    } catch ({ errors }) {
        setErrors(errors)
    }
}

const queryClient = useQueryClient()
const onSave = handleSubmit(
    async ({
        contact: _,
        customer: __,
        contact_currency: ___,
        document,
        ...values
    }) => {
        try {
            emitter.emit('set-loading', true)

            let uploadKey = null
            if (document) {
                uploadKey = await uploadStorage(document)
            }
            await executeCreate({
                ...values,
                document: uploadKey,
            })

            await queryClient.resetQueries({
                queryKey: ['bank-transactions-reconcile'],
            })

            successNotify({
                title: t('general.success'),
                text: t('purchasesExpense.create.addSuccess'),
            })
        } finally {
            emitter.emit('set-loading', false)
        }
    }
)
const resetFieldError = (field) => {
    setErrors({ [field]: undefined })
}

const onTextractSuccess = async (fields) => {
    for (const [key, value] of Object.entries(fields)) {
        if (key === 'line_items') {
            if (value.length < 1) {
                continue
            }

            setFieldValue('itemize', value.length > 1)
            setFieldValue(key, value)
        } else {
            setFieldValue(key, value)
        }
    }
}
</script>
