<template>
    <form-simple-upload
        v-model="document"
        :error-message="documentError"
        container-class="h-full"
        class="col-span-2 flex flex-col"
        :description="$t('purchasesExpense.create.uploadDescription')"
        :acceptedFiles="['application/pdf', 'image/*']"
        :on-custom-upload-file="handleUploadFile"
        :custom-name="customFileName"
        :custom-path="customFilePath"
    >
        <template #modal-title>
            {{ $t('purchasesExpense.simpleUpload.title') }}
        </template>
        <template #modal-description>
            {{ $t('purchasesExpense.simpleUpload.description') }}
        </template>
        <template #before-img>
            <template v-for="highlight in highlights" :key="highlight.field">
                <document-extraction-textract-highlight
                    v-if="highlight?.bounding"
                    :bounding="highlight.bounding"
                    @change="(state) => onHighlight(highlight.field, state)"
                />
            </template>
        </template>
    </form-simple-upload>
</template>

<script setup>
import {
    convertTextractToFields,
    convertTextractToHighlight,
} from '@tenant/utils/helper'

const emit = defineEmits(['textract-success'])
const props = defineProps({
    canUpload: {
        type: Boolean,
        default: false,
    },
})
const { setHighlightValue } = useHighlight()

const highlights = ref([])

const { executeSingle } = useUploadFile(
    '/api/purchase/extract-document/expense'
)

const { value: document, errorMessage: documentError } = useField('document')

const customFileName = (file) => {
    return file.full_name
}

const customFilePath = (file) => {
    return file.preview?.path
}

const handleUploadFile = async (file, { setLoading }) => {
    if (!props.canUpload) {
        return
    }

    if (document.value) {
        return
    }

    try {
        setLoading(true)
        const response = await executeSingle(file, 'document')
        const mappingResponse = mappingUploadResponse(response)
        highlights.value = convertTextractToHighlight(mappingResponse)
        emit('textract-success', convertTextractToFields(mappingResponse))
    } finally {
        setLoading(false)
    }
}

const mappingUploadResponse = (response) => {
    Object.assign(response, {
        date: response['date_of_document'],
        reference: response['document_number'],
    })

    return response
}

const onHighlight = (field, value) => setHighlightValue(field, value)
</script>
