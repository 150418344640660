<template>
    <div class="h-full">
        <div class="flex h-full">
            <!-- Sidebar/Left -->
            <base-compact-list
                class="min-w-92"
                :items="expenses"
                :is-loading="isLoadingExpenses"
                selectable
                :initial-selected-item="selectedItem"
                @item-click="onSidebarItem"
                @load-more="onLoadMore"
                @item-check="onItemCheck"
            >
                <template #header="{ checkedItems }">
                    <template v-if="checkedItems.length">
                        <div class="flex items-center gap-2.5">
                            <span class="text-sm text-gray-700">
                                {{
                                    $t(
                                        'purchasesBill.detail.selectedText',
                                        checkedItems.length
                                    )
                                }}
                            </span>
                        </div>
                        <base-context-menu>
                            <base-context-menu-item
                                v-if="$acl.can('delete_expenses')"
                                icon="line-icons:general:trash-01"
                                @click="onDeleteExpenses"
                            >
                                {{ $t('general.delete') }}
                            </base-context-menu-item>
                        </base-context-menu>
                    </template>

                    <template v-else>
                        <!-- Select filter -->
                        <base-dropdown
                            v-if="!checkedItems.length"
                            size="sm"
                            variant="default"
                            class="bg-white"
                        >
                            <template #btn-content>
                                {{ $t('purchasesExpense.detail.allExpenses') }}

                                <base-icon
                                    name="line-icons:arrows:chevron-down"
                                    variant="inherit"
                                />
                            </template>

                            <template #default>
                                <base-dropdown-item>
                                    {{
                                        $t(
                                            'purchasesExpense.detail.allExpenses'
                                        )
                                    }}
                                </base-dropdown-item>
                            </template>
                        </base-dropdown>

                        <base-button
                            icon="line-icons:general:plus"
                            :label="$t('general.new')"
                            size="sm"
                            @click="redirectToNew"
                            v-if="$acl.can('create_expenses')"
                        />
                    </template>
                </template>

                <template #item-content="{ item, clickItem }">
                    <div
                        class="flex w-full flex-col gap-1"
                        @click="clickItem(item)"
                    >
                        <div
                            class="flex items-center justify-between gap-5 text-sm"
                        >
                            <span
                                class="max-w-[12rem] truncate font-medium text-primary-600"
                            >
                                <template v-if="!item.itemize">
                                    {{
                                        item.line_items[0]?.chart_of_account
                                            ?.name
                                    }}
                                </template>
                                <template v-else>
                                    {{ $t('general.itemized') }}
                                </template>
                            </span>
                            <span class="font-normal text-gray-700">
                                {{
                                    $filters.symbolCurrency(
                                        item.amount_gross_total,
                                        item.currency
                                    )
                                }}
                            </span>
                        </div>
                        <div
                            class="flex items-center justify-between gap-5 whitespace-nowrap text-xs text-gray-500"
                        >
                            <span class="flex items-center font-normal">
                                {{ $filters.dateHumanFormat(item.date) }}
                                <span
                                    class="mx-2 inline-block h-3 w-px bg-gray-300"
                                />
                                <span class="max-w-[6rem] truncate">
                                    {{ getContactName(item.contact) }}
                                </span>
                            </span>
                            <base-icon
                                name="line-icons:files:paperclip"
                                variant="inherit"
                                size="sm"
                            />
                        </div>
                    </div>
                </template>
            </base-compact-list>

            <!-- Main/Right -->
            <div class="flex h-full grow flex-col" v-if="!isLoadingDetail">
                <!-- Top bar -->
                <div class="flex items-center justify-between border-b p-5">
                    <div class="mr-auto text-lg font-medium text-gray-900">
                        {{ $t('purchasesExpense.detail.title') }}
                    </div>
                    <div class="flex gap-2.5">
                        <base-button
                            icon="line-icons:editor:pencil-01"
                            :label="$t('general.edit')"
                            size="sm"
                            variant="default"
                            :disabled="!selectedItem"
                            @click="redirectEdit"
                            v-if="$acl.can('update_expenses')"
                        />
                        <base-button
                            icon="line-icons:media-&-devices:printer"
                            label="Print"
                            size="sm"
                            variant="default"
                            @click="printContent('print-expense')"
                        />
                    </div>
                    <expenses-context-menu
                        item="item"
                        @delete-expense="openDeleteModal"
                    />
                </div>

                <!-- Content -->
                <div
                    class="grow overflow-auto p-6"
                    v-if="selectedItem"
                    id="print-expense"
                >
                    <div
                        class="mb-6 grid grid-cols-3 gap-6 font-normal text-gray-700"
                    >
                        <div class="col-span-2">
                            <p class="text-xs font-medium">
                                {{
                                    $t('purchasesExpense.detail.expenseAmount')
                                }}
                            </p>
                            <div class="mb-3 flex items-end gap-1">
                                <p class="text-lg text-danger-600">
                                    {{
                                        $filters.symbolCurrency(
                                            selectedItem.amount_gross_total,
                                            selectedItem.currency
                                        )
                                    }}
                                </p>
                                <p class="text-xs font-medium">
                                    {{
                                        $t('general.onDate', {
                                            date: $filters.dateHumanFormat(
                                                selectedItem.date
                                            ),
                                        })
                                    }}
                                </p>
                            </div>
                            <base-badge
                                class="mb-6"
                                :label="
                                    $t('expenseStatus', {
                                        status: selectedItem.status,
                                    })
                                "
                                :variant="getStatusVariant(selectedItem.status)"
                            />

                            <p class="mb-2.5 text-xs font-medium">
                                {{ $t('purchasesExpense.detail.account') }}
                            </p>
                            <base-badge variant="gray" class="mb-6">
                                <template #label>
                                    <template v-if="!selectedItem.itemize">
                                        {{
                                            selectedItem.line_items[0]
                                                ?.chart_of_account?.name
                                        }}
                                    </template>
                                    <template v-else>
                                        {{ $t('general.itemized') }}
                                    </template>
                                </template>
                            </base-badge>

                            <div class="flex flex-col gap-2.5">
                                <div>
                                    <p class="text-xs font-medium">
                                        {{ $t('purchasesExpense.detail.ref') }}
                                    </p>
                                    <p class="text-xs text-gray-500">
                                        {{ selectedItem.reference }}
                                    </p>
                                </div>

                                <div>
                                    <p class="text-xs font-medium">
                                        {{
                                            $t('purchasesExpense.detail.paidTo')
                                        }}
                                    </p>
                                    <p
                                        class="text-xs font-semibold text-primary-600"
                                    >
                                        {{
                                            getContactName(
                                                selectedItem.customer
                                            )
                                        }}
                                    </p>
                                </div>

                                <template v-if="!selectedItem.itemize">
                                    <div>
                                        <p class="text-xs font-medium">
                                            {{
                                                $t(
                                                    'purchasesExpense.detail.vat'
                                                )
                                            }}
                                        </p>
                                        <p class="text-xs text-gray-500">
                                            {{
                                                $t(
                                                    'purchasesExpense.detail.vat',
                                                    {
                                                        vat: selectedItem
                                                            .line_items[0]
                                                            .tax_rule.tax_rate,
                                                    }
                                                )
                                            }}
                                        </p>
                                    </div>
                                    <div>
                                        <p class="text-xs font-medium">
                                            {{
                                                $t(
                                                    'purchasesExpense.detail.taxAmount'
                                                )
                                            }}
                                        </p>
                                        <p class="text-xs text-gray-500">
                                            {{
                                                $t(
                                                    'purchasesExpense.detail.taxExclusive',
                                                    {
                                                        amount: $filters.symbolCurrency(
                                                            getTaxAmount(
                                                                selectedItem
                                                                    .line_items[0]
                                                            ),
                                                            selectedItem.currency
                                                        ),
                                                    }
                                                )
                                            }}
                                        </p>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="mt-6 flex flex-col">
                                        <div
                                            class="flex justify-between border-b border-gray-200 bg-gray-25 px-6 py-3 text-xs font-medium text-gray-500"
                                        >
                                            <span>
                                                {{
                                                    $t(
                                                        'purchasesExpense.detail.expenseAccount'
                                                    )
                                                }}
                                            </span>
                                            <span>
                                                {{
                                                    $t(
                                                        'purchasesExpense.detail.amount'
                                                    )
                                                }}
                                            </span>
                                        </div>
                                        <div
                                            v-for="lineItem in selectedItem.line_items"
                                            :key="lineItem.id"
                                            class="flex justify-between border-b border-gray-200 px-6 py-3 text-sm font-normal text-gray-700"
                                        >
                                            <span>
                                                {{
                                                    lineItem.chart_of_account
                                                        .name
                                                }}
                                            </span>
                                            <span>
                                                {{
                                                    $filters.symbolCurrency(
                                                        lineItem.amount,
                                                        selectedItem.currency
                                                    )
                                                }}
                                            </span>
                                        </div>
                                        <div
                                            class="flex justify-between bg-gray-50 px-6 py-3 text-sm font-semibold text-gray-700"
                                        >
                                            <span>
                                                {{
                                                    t(
                                                        'purchasesExpense.detail.expenseTotal'
                                                    )
                                                }}
                                            </span>
                                            <span>
                                                {{
                                                    $filters.symbolCurrency(
                                                        getTotalLineItemAmount(
                                                            selectedItem
                                                        ),
                                                        selectedItem.currency
                                                    )
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div
                            v-if="selectedItem.document"
                            class="relative flex items-center justify-center rounded-lg border border-gray-200 p-4"
                        >
                            <base-img
                                v-if="
                                    isDocumentImageFile(selectedItem.document)
                                "
                                :src="selectedItem.document.preview.path"
                                container-class="w-full object-contain"
                            />
                            <div
                                v-else
                                class="flex w-full flex-col items-center justify-center gap-2.5"
                            >
                                <img
                                    v-if="
                                        isDocumentPdfFile(selectedItem.document)
                                    "
                                    :src="
                                        $filters.asset('static/images/pdf.png')
                                    "
                                    class="w-20 object-contain"
                                />
                                <base-icon
                                    v-else
                                    name="line-icons:files:file-04"
                                    size="4xl"
                                />
                                <span>
                                    {{ selectedItem.document.full_name }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <journal-history-collapse
                        :queries="{
                            filter: {
                                'journalEntry.journalable_id': route.params.id,
                                'journalEntry.transaction_type':
                                    JOURNAL_TRANSACTION_TYPE.EXPENSE,
                            },
                        }"
                    />
                </div>
            </div>
        </div>
    </div>

    <expenses-delete-modal
        v-if="selectedItem"
        :expense-id="selectedItem.id"
        :loading="loadingDeleteExpenses"
        :show="showDeleteModal"
        @modal-close="showDeleteModal = false"
        @modal-confirm="onDeleteExpense"
    />

    <expenses-delete-failure-modal
        :show="showCannotDeleteModal"
        @modal-close="showCannotDeleteModal = false"
    />
</template>

<script setup>
import BigNumber from 'bignumber.js'
import { uniqBy } from 'lodash-es'
import { useInfiniteQuery } from '@tanstack/vue-query'
import {
    getContactName,
    isDocumentImageFile,
    isDocumentPdfFile,
    printContent,
} from '@tenant/utils/helper'
import { getStatusVariant } from '@tenant/modules/tenant/purchases-expense/utils/helper'
import { JOURNAL_TRANSACTION_TYPE } from '@tenant/utils/constants'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const { useApiSearch, setEntity } = useApiFactory('expense')
const { execute } = useApiSearch({}, false)
const { successNotify, errorNotify } = useNotification()

useHead({
    title: t('purchasesExpense.detail.title'),
})

const {
    execute: getDetail,
    result: selectedItem,
    loading: isLoadingDetail,
} = useApi('api/expense/{id}', 'GET')

// Items in sidebar
const {
    data: expenses,
    isFetching: isLoadingExpenses,
    fetchNextPage: fetchMore,
    hasNextPage: hasMore,
} = useInfiniteQuery({
    queryKey: ['expenses'],
    queryFn: ({ pageParam = 1 }) => {
        return execute({
            page: pageParam,
            limit: 20,
            ...route.query,
        })
    },
    getNextPageParam: ({ current_page, last_page }) => {
        return current_page < last_page ? current_page + 1 : undefined
    },
    select: (res) =>
        uniqBy(
            res?.pages?.flatMap((x) => x?.data),
            (x) => x?.id
        ),
})

onMounted(() => {
    getExpenseDetail()
})

const onLoadMore = () => {
    if (hasMore.value) {
        fetchMore()
    }
}

const onSidebarItem = (item) => {
    router.replace({
        params: { id: item.id },
    })
}

watch(
    () => route.params.id,
    () => {
        if (route.name !== 'purchases.expense.detail') {
            return
        }

        getExpenseDetail()
    }
)

const getExpenseDetail = () => {
    getDetail({
        params: {
            id: route.params.id,
        },
    })
}

const getTaxAmount = (lineItem) => {
    const amount = lineItem.amount
    const taxRate = lineItem.tax_rule.tax_rate

    return amount * (taxRate / 100)
}

const getTotalLineItemAmount = (item) => {
    return item.line_items
        .reduce((acc, i) => acc.plus(i.amount), new BigNumber(0.0))
        .toNumber()
}

const redirectToNew = () => {
    router.push({ name: 'purchases.expense.create' })
}

const redirectEdit = () => {
    router.push({
        name: 'purchases.expense.edit',
        params: {
            id: selectedItem.value.id,
        },
    })
}

const { execute: executeDeleteExpenses, loading: loadingDeleteExpenses } =
    useApi('/api/expense', 'DELETE')

const showDeleteModal = ref(false)
const showCannotDeleteModal = ref(false)

const checkedItems = ref([])
const onItemCheck = ({ checkedItems: items }) => {
    checkedItems.value = items
}

const onDeleteExpense = async (id) => {
    try {
        await executeDeleteExpenses({
            data: {
                ids: [id],
            },
        })

        successNotify({
            title: t('purchasesExpense.deleteSuccess'),
        })

        await router.push({
            name: 'purchases.expense',
        })
    } catch ({ hasErrors, errorMessage }) {
        showCannotDeleteModal.value = true
        if (hasErrors) {
            errorNotify({
                title: errorMessage,
            })
        }
    } finally {
        showDeleteModal.value = false
    }
}

const onDeleteExpenses = async () => {
    try {
        await executeDeleteExpenses({
            data: {
                ids: checkedItems.value.map((item) => item.id),
            },
        })

        successNotify({
            title: t('purchasesExpense.deleteSuccess'),
        })

        await router.push({
            name: 'purchases.expense',
        })
    } catch ({ hasErrors, errorMessage }) {
        showCannotDeleteModal.value = true
        if (hasErrors) {
            errorNotify({
                title: errorMessage,
            })
        }
    }
}

const openDeleteModal = (item) => {
    setEntity(item)
    showDeleteModal.value = true
}
</script>
