<template>
    <div class="grid grid-cols-7 gap-4" v-if="defaultCurrency">
        <div class="col-span-5 flex flex-col gap-4">
            <form-control-group-row :grow="false">
                <form-contact-select
                    v-model="contactId"
                    :error-message="contactIdError"
                    :highlight="supplierNameHighlight"
                    :label="$t('purchasesExpense.create.supplier.label')"
                    :placeholder="
                        $t('purchasesExpense.create.supplier.placeholder')
                    "
                    :input-display="getContactName(contact)"
                    @on-contact-changed="onContactChange"
                />
                <form-text-input
                    v-model="reference"
                    :error-message="referenceError"
                    :highlight="referenceHighlight"
                    :label="$t('purchasesExpense.create.reference.label')"
                    :placeholder="
                        $t('purchasesExpense.create.reference.placeholder')
                    "
                />
                <form-date-picker
                    v-model="date"
                    :error-message="dateError"
                    :highlight="dateHighlight"
                    single
                    auto-apply
                    :label="$t('purchasesExpense.create.date.label')"
                />
            </form-control-group-row>

            <form-control-group-row :grow="false">
                <form-contact-select
                    v-model="customerId"
                    :error-message="customerIdError"
                    :label="$t('purchasesExpense.create.customer.label')"
                    :placeholder="
                        $t('purchasesExpense.create.customer.placeholder')
                    "
                    :input-display="getContactName(customer)"
                    @on-contact-changed="onCustomerChange"
                />
                <div class="mt-7 flex w-full">
                    <form-toggle
                        v-if="customerId"
                        v-model="billable"
                        :label="$t('purchasesExpense.create.billable.label')"
                        size="md"
                    >
                        <template #hint>
                            <span class="text-sm font-normal text-gray-500">
                                {{
                                    $t('purchasesExpense.create.billable.hint')
                                }}
                            </span>
                        </template>
                    </form-toggle>
                </div>
            </form-control-group-row>

            <form-control-group-row :grow="false">
                <form-date-picker
                    v-model="paymentDate"
                    :error-message="paymentDateError"
                    single
                    auto-apply
                    :label="$t('purchasesExpense.create.paymentDate.label')"
                />
                <form-single-select
                    v-model="paymentAccount"
                    :error-message="paymentAccountError"
                    :label="$t('purchasesExpense.create.paymentAccount.label')"
                    :placeholder="
                        $t('purchasesExpense.create.paymentAccount.placeholder')
                    "
                    :options="paymentAccounts"
                    option-value="id"
                    option-label="name"
                />
            </form-control-group-row>

            <template v-if="!itemize">
                <form-control-group-row
                    v-for="{ value, key } in fields"
                    :key="key"
                    :grow="false"
                >
                    <form-field-array-error
                        :name="`line_items[${key}].chart_of_account_id`"
                        #="{ errorMessage }"
                    >
                        <div class="flex w-full flex-col gap-2">
                            <form-detail-chart-of-account
                                v-model="value.chart_of_account_id"
                                :error-message="errorMessage"
                                :input-display="
                                    getChartOfAccountName(
                                        value.chart_of_account
                                    )
                                "
                                :label="
                                    $t(
                                        'purchasesExpense.create.expenseAccount.label'
                                    )
                                "
                                :placeholder="
                                    $t(
                                        'purchasesExpense.create.expenseAccount.placeholder'
                                    )
                                "
                                @on-close="
                                    resetFieldError(
                                        `line_items[${key}].chart_of_account_id`
                                    )
                                "
                                type="expense"
                            />

                            <purchases-expense-suggest-coa
                                v-if="!value.chart_of_account_id"
                                :field="value"
                                :key-field="0"
                                @replace-coa="onReplaceCoa"
                            />
                        </div>
                    </form-field-array-error>

                    <form-field-array-error
                        :name="`line_items[${key}].description`"
                        #="{ errorMessage }"
                    >
                        <form-text-input
                            v-model="value.description"
                            :highlight="lineItemsHighlight?.[key]?.description"
                            hide-message
                            :label="
                                $t('purchasesExpense.create.description.label')
                            "
                            :placeholder="
                                $t(
                                    'purchasesExpense.create.description.placeholder'
                                )
                            "
                            :error-message="errorMessage"
                            @blur="
                                resetFieldError(
                                    `line_items[${key}].description`
                                )
                            "
                        />
                    </form-field-array-error>

                    <form-field-array-error
                        :name="`line_items[${key}].amount`"
                        #="{ errorMessage }"
                    >
                        <div class="flex w-full flex-col gap-2">
                            <form-number-input
                                v-model="value.amount"
                                hide-message
                                :label="
                                    $t('purchasesExpense.create.amount.label')
                                "
                                :placeholder="
                                    $t(
                                        'purchasesExpense.create.amount.placeholder'
                                    )
                                "
                                :error-message="errorMessage"
                                :precision="2"
                                input-class="pr-16"
                                @blur="
                                    resetFieldError(`line_items[${key}].amount`)
                                "
                            >
                                <template #right>
                                    <div
                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center border-l border-gray-300 px-3"
                                    >
                                        {{ convertCurrencyIso }}
                                    </div>
                                </template>
                                <template #tooltip="{ errorMessage }">
                                    <div
                                        v-if="errorMessage"
                                        class="pointer-events-none absolute inset-y-0 right-12 flex items-center pr-3"
                                    >
                                        <base-icon
                                            name="line-icons:alerts-&-feedback:alert-circle"
                                            variant="danger"
                                            size="sm"
                                        />
                                    </div>
                                </template>
                            </form-number-input>

                            <p
                                class="ml-auto text-xs text-gray-400"
                                v-if="
                                    exchangeRate &&
                                    convertCurrencyIso !== defaultCurrency?.iso
                                "
                            >
                                {{
                                    $t('general.lineItemsExchangeRate', {
                                        now: $filters.dateCustomFormat(
                                            exchangeRate.date,
                                            'YYYY-MM-DD'
                                        ),
                                        fromIso: convertCurrencyIso,
                                        toIso: defaultCurrency?.iso,
                                        factor,
                                    })
                                }}
                            </p>
                        </div>
                    </form-field-array-error>

                    <form-field-array-error
                        :name="`line_items[${key}].tax_rule_id`"
                        #="{ errorMessage }"
                    >
                        <div class="flex w-full flex-col gap-2">
                            <form-single-select
                                v-model="value.tax_rule_id"
                                :error-message="errorMessage"
                                hide-message
                                :label="$t('purchasesExpense.create.tax.label')"
                                :placeholder="
                                    $t(
                                        'purchasesExpense.create.tax.placeholder'
                                    )
                                "
                                :options="taxRules"
                                option-value="id"
                                :option-label="customTaxLabel"
                                @on-close="
                                    resetFieldError(
                                        `line_items[${key}].tax_rule_id`
                                    )
                                "
                            />

                            <p class="ml-auto text-xs text-gray-400">
                                {{
                                    $t('purchasesExpense.form.taxAmount', {
                                        amount: $filters.currency(
                                            totalVat,
                                            convertCurrencyIso
                                        ),
                                    })
                                }}
                            </p>
                        </div>
                    </form-field-array-error>
                </form-control-group-row>
            </template>

            <div
                class="-mt-2.5 flex justify-end gap-5"
                v-if="(fields[0]?.value.tax_rule_id && !itemize) || itemize"
            >
                <div class="mt-2.5 flex justify-between gap-3" v-if="itemize">
                    <form-radio
                        name="taxType"
                        class="w-full"
                        v-model="taxType"
                        :value="AMOUNT_TYPE.GROSS"
                        :text="$t('general.taxIncl')"
                    />
                    <form-radio
                        name="relationship"
                        class="w-full"
                        v-model="taxType"
                        :value="AMOUNT_TYPE.NET"
                        :text="$t('general.taxExcl')"
                    />
                </div>
            </div>

            <template v-if="!itemize">
                <div class="flex items-center justify-between text-primary-700">
                    <div
                        class="flex cursor-pointer items-center gap-2"
                        @click="backToItemize"
                    >
                        <base-icon
                            name="line-icons:development:dataflow-04"
                            variant="inherit"
                        />
                        <span class="text-sm font-medium">
                            {{ $t('general.itemize') }}
                        </span>
                    </div>

                    <div
                        class="flex justify-between gap-3"
                        v-if="fields[0]?.value.tax_rule_id && !itemize"
                    >
                        <form-radio
                            name="taxType"
                            class="w-full"
                            v-model="taxType"
                            :value="AMOUNT_TYPE.GROSS"
                            :text="$t('general.taxIncl')"
                        />
                        <form-radio
                            name="relationship"
                            class="w-full"
                            v-model="taxType"
                            :value="AMOUNT_TYPE.NET"
                            :text="$t('general.taxExcl')"
                        />
                    </div>
                </div>
            </template>
        </div>

        <purchases-expense-form-upload
            :can-upload="canUpload"
            @textract-success="onTextractSuccess"
        />

        <no-payment-account-modal
            :show="showNoPaymentAccountModal && !paymentAccounts.length"
            @modal-close="showNoPaymentAccountModal = false"
        />
    </div>
</template>

<script setup>
import { getChartOfAccountName, getContactName } from '@tenant/utils/helper'
import { useField } from 'vee-validate'
import { AMOUNT_TYPE } from '@tenant/utils/constants'
import { useHighlight } from '@tenant/composables/use-highlight'
import { useExchangeRate } from '@tenant/composables/use-exchange-rate'

const emit = defineEmits(['textract-success'])
const props = defineProps({
    oldLineItems: {
        type: Array,
        default: null,
    },
    canUpload: {
        type: Boolean,
        default: false,
    },
})

const emitter = useEmitter()

const { defaultCurrency, isLoading } = useCurrencies()
const { paymentAccounts } = usePaymentAccounts()
const { taxRules } = useTaxRules(['purchases'])

const { value: referenceHighlight } = useHighlight('reference')
const { value: dateHighlight } = useHighlight('date')
const { value: supplierNameHighlight } = useHighlight('supplier_name')
const { value: lineItemsHighlight } = useHighlight('line_items')

const { value: contactId, errorMessage: contactIdError } =
    useField('contact_id')
const { value: contact } = useField('contact')
const { value: customerId, errorMessage: customerIdError } =
    useField('customer_id')
const { value: customer } = useField('customer')
const { value: billable } = useField('billable')
const { value: paymentAccount, errorMessage: paymentAccountError } =
    useField('payment_account_id')
const { value: reference, errorMessage: referenceError } = useField('reference')
const { value: date, errorMessage: dateError } = useField('date')
const { value: paymentDate, errorMessage: paymentDateError } =
    useField('payment_date')
const { value: itemize, setValue: setItemize } = useField('itemize')
const { value: taxType } = useField('tax_type')

const { fields, replace } = useFieldArray('line_items')

const { toItemize } = useItemize({
    replace,
    oldLineItems: toRef(props, 'oldLineItems'),
    fields,
})

watch(
    () => date.value,
    (value) => {
        paymentDate.value = value
    }
)

const customTaxLabel = (option) => `${option.name} (${option.tax_rate})`

const resetFieldError = (field) => {
    emitter.emit('reset-field-error', field)
}

watch(
    () => isLoading.value,
    (value) => {
        emitter.emit('set-loading', value)
    }
)

onMounted(() => {
    emitter.emit('set-loading', isLoading.value)
})

const backToItemize = () => {
    setItemize(true)
    nextTick(() => {
        toItemize()
    })
}

const onContactChange = ({ item, id }) => {
    contact.value = item
    contactId.value = id
}

const onCustomerChange = ({ item, id }) => {
    customer.value = item
    customerId.value = id
}

const onTextractSuccess = async (fields) => {
    emit('textract-success', fields)
}

const onReplaceCoa = (keyField, coa) => {
    fields.value[keyField].value.chart_of_account_id = coa.id
    fields.value[keyField].value.chart_of_account_name =
        getChartOfAccountName(coa)
}

const { totalGross, totalNet } = useNetGross(fields, taxType)
const totalVat = computed(() => totalGross.value - totalNet.value)

const { value: exchangeRate } = useField('exchange_rate')
const { value: currency } = useField('currency_id')
const { value: contactCurrency } = useField('contact_currency')

// FACTOR BASED ON EXCHANGE RATE
const {
    iso: convertCurrencyIso,
    factor,
    rate,
} = useExchangeRate(contactCurrency)

watch(
    () => rate.value,
    () => {
        exchangeRate.value = rate.value
    }
)

watch(
    contact,
    () => {
        currency.value = contact.value?.currency_id
        contactCurrency.value = contact.value?.currency
    },
    {
        immediate: true,
    }
)

// NO PAYMENT ACCOUNT MODAL
const showNoPaymentAccountModal = ref(true)
// END: NO PAYMENT ACCOUNT MODAL
</script>
