import { useApiFactory } from '@tenant/composables'

export const useGetExpense = (queries = {}, shouldWatch = true) => {
    const { useApiSearch, entities } = useApiFactory('expense')

    const { loading, execute, executeRefreshSearch } = useApiSearch(
        {
            ...queries,
        },
        shouldWatch
    )

    return {
        entities,
        loading,
        execute,
        executeRefreshSearch,
    }
}
