<template>
    <form-control-group-row class="relative">
        <base-loading size="sm" v-if="loading" />

        <base-tooltip
            v-if="!loading && suggestCOA"
            dark
            hover
            :content="$t('purchasesExpense.replaceCOATooltip')"
        >
            <base-badge
                :label="suggestCOA.name"
                class="cursor-pointer gap-2 !p-2"
                variant="gray"
                @click="onClick"
                :loading="loading"
            >
                <template #left>
                    <base-icon
                        class="pr-2"
                        variant="gray"
                        name="duocolor-icons:arrows:arrows-right"
                    />
                </template>
            </base-badge>
        </base-tooltip>
    </form-control-group-row>
</template>

<script setup>
import { useDebounceFn } from '@vueuse/core'

const { executeQueryFindCOA: executeFindCOA, loading } = useVector()

const contact = useFieldValue('contact')
const supplierName = useFieldValue('supplier_name')
const paymentDate = useFieldValue('payment_date')

const props = defineProps({
    field: {
        type: Object,
        default: null,
    },
    keyField: {
        type: Number,
        default: null,
    },
})

const suggestCOA = ref(null)
const emit = defineEmits(['replace-coa'])

const getContactName = (contact) => {
    if (!contact) return null

    return contact.person
        ? `${contact.person.first_name} ${contact.person.last_name}`
        : contact.organisation?.name
}

watch(
    () => props.field,
    useDebounceFn(async (field) => {
        if (!field || field.chart_of_account_id || !field.description) {
            return
        }

        const data = {
            transactionDetails: field.description,
            vendor: getContactName(contact.value) ?? supplierName.value,
            date: paymentDate.value,
        }

        suggestCOA.value = await executeFindCOA({
            query: data,
            // filter: {
            //     type: 'expense',
            // },
            topK: 1,
        })
    }, 500),
    { immediate: true, deep: true }
)

const onClick = () => {
    emit('replace-coa', props.keyField, suggestCOA.value)
}
</script>
