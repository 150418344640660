<template>
    <div class="relative mt-6 border-t pt-6" v-if="iso">
        <div class="mb-5 flex border-b text-xl">
            <div class="grow">{{ $t('createInvoice.netAmount') }}</div>
            <div>
                {{ $filters.currency(amountNet, iso) }}
            </div>
        </div>
        <div
            class="mb-5 flex border-b text-xl"
            v-for="(value, key) in vatAmounts"
            :key="key"
        >
            <div class="grow">
                {{ $t('createInvoice.vat', { n: value.taxRule?.tax_rate }) }}
            </div>
            <div>
                {{ $filters.currency(value.vat, iso) }}
            </div>
        </div>
        <div class="flex text-2xl font-bold">
            <div class="grow">{{ $t('createInvoice.total') }}</div>
            <div class="text-xl">
                {{ $filters.currency(amountGross, iso) }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { useExchangeRate } from '@tenant/composables/use-exchange-rate'

const { fields } = useFieldArray('line_items')
const taxType = useFieldValue('tax_type')

const { totalGross, totalNet, vatAmounts } = useNetGross(fields, taxType)

const { value: amountNet } = useField('amount_net_total')
const { value: amountGross } = useField('amount_gross_total')

// Update `amount_net_total` and `amount_gross_total` fields based on computed values from `useInvoice`
watch(totalGross, () => (amountGross.value = totalGross.value), {
    immediate: true,
})
watch(totalNet, () => (amountNet.value = totalNet.value), {
    immediate: true,
})

const contactCurrency = useFieldValue('contact_currency')
const { iso } = useExchangeRate(contactCurrency)
</script>
