<template>
    <base-sticky-heading
        :title="$t('purchasesExpense.edit.title')"
        class="min-h-[82px] items-center"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button variant="default" @click="onCancel">
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button variant="default" @click="onSaveAndNew">
                    {{ $t('general.saveAndNew') }}
                </base-button>
                <base-button
                    variant="primary"
                    icon="line-icons:general:save-01"
                    @click="onUpdate"
                >
                    {{ $t('general.save') }}
                </base-button>
            </div>
        </template>

        <div class="px-8 py-6">
            <base-alert
                v-if="status === EXPENSE_STATUS.INVOICED"
                class="mb-6"
                variant="warning"
                :title="$t('purchasesExpense.edit.invoiceWarning.title')"
                :description="
                    $t('purchasesExpense.edit.invoiceWarning.description')
                "
            />
            <purchases-expense-form-general />

            <purchases-expense-line-items :old-line-items="oldLineItems" />

            <purchases-expense-total />
        </div>
    </base-sticky-heading>

    <transaction-book-close-modal
        :code="code"
        :date="parameters?.date"
        @modal-close="code = null"
    />
</template>

<script setup>
import { EXPENSE_STATUS } from '@tenant/modules/tenant/purchases-expense/utils/constants'
import { shortServerFormat } from '@tenant/utils/day'
import { useQueryClient } from '@tanstack/vue-query'

const emitter = useEmitter()
const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const { successNotify } = useNotification()

const { uploadStorage } = useUploadStorage()
const { useApiGet, useApiSave } = useApiFactory('expense')
const { execute: executeGet } = useApiGet()
const { execute: executeSave, code, parameters } = useApiSave()

const oldLineItems = ref([])

useHead({
    title: t('purchasesExpense.edit.title'),
})

const { handleSubmit, setErrors, setValues } = useForm({
    initialValues: {
        itemize: false,
    },
})

const { value: status } = useField('status')

onMounted(() => {
    emitter.on('reset-field-error', resetFieldError)
})

onMounted(async () => {
    try {
        emitter.emit('set-loading', true)

        const {
            itemize,
            billable,
            status,
            contact,
            contact_id,
            customer,
            customer_id,
            tax_type,
            payment_account_id,
            reference,
            date,
            payment_date,
            document,
            line_items,
            exchange_rate,
        } = await executeGet(route.params.id)

        setValues({
            billable,
            status,
            itemize,
            contact,
            contact_id,
            customer,
            customer_id,
            tax_type,
            payment_account_id,
            reference,
            date: shortServerFormat(date),
            payment_date: shortServerFormat(payment_date),
            document,
            line_items,
            exchange_rate,
        })

        oldLineItems.value = line_items
    } finally {
        emitter.emit('set-loading', false)
    }
})

const onCancel = () => {
    router.push({
        name: 'purchases.expense',
    })
}

const onUpdate = async () => {
    try {
        await onSave()
        await router.push({
            name: 'purchases.expense.list',
        })
    } catch ({ errors }) {
        setErrors(errors)
    }
}

const onSaveAndNew = async () => {
    try {
        await onSave()
        await router.push({
            name: 'purchases.expense.create',
        })
    } catch ({ errors }) {
        setErrors(errors)
    }
}

const queryClient = useQueryClient()
const onSave = handleSubmit(
    async ({
        contact: _,
        customer: __,
        contact_currency: ___,
        document,
        ...values
    }) => {
        try {
            emitter.emit('set-loading', true)

            let uploadKey = null
            if (document) {
                uploadKey = await uploadStorage(document)
            }

            await executeSave(
                {
                    ...values,
                    document: uploadKey,
                },
                route.params.id,
                {
                    method: 'POST',
                }
            )

            await queryClient.resetQueries({
                queryKey: ['bank-transactions-reconcile'],
            })

            successNotify({
                title: t('general.success'),
                text: t('purchasesExpense.edit.editSuccess'),
            })
        } finally {
            emitter.emit('set-loading', false)
        }
    }
)

const resetFieldError = (field) => {
    setErrors({ [field]: undefined })
}
</script>
