<template>
    <div v-if="itemize">
        <hr class="mb-6 mt-6 bg-gray-200" />
        <div class="mb-1.5 flex items-center justify-between">
            <button
                v-if="canBackSingleView"
                class="flex cursor-pointer items-center gap-2 text-primary-700"
                @click="backToSingleView"
            >
                <base-icon
                    name="line-icons:arrows:chevron-left"
                    variant="inherit"
                />
                <span class="text-sm font-medium">
                    {{ $t('purchasesExpense.create.backToSingleExpenseView') }}
                </span>
            </button>

            <base-tooltip
                v-else
                dark
                hover
                placement="top"
                :content="$t('hint.canNotBackSingleView')"
            >
                <div class="flex items-center gap-2 text-gray-300">
                    <base-icon
                        name="line-icons:arrows:chevron-left"
                        variant="inherit"
                    />
                    <span class="text-sm font-medium">
                        {{
                            $t(
                                'purchasesExpense.create.backToSingleExpenseView'
                            )
                        }}
                    </span>
                </div>
            </base-tooltip>
        </div>

        <form-field-array
            :fields="fields"
            :label-add="$t('general.addNewRow')"
            :min="1"
            @on-remove-item="onRemoveItem"
            @on-add-item="onAddItem"
        >
            <template #default="{ item: { value, key }, idx }">
                <form-control-group
                    class="flex-1 rounded-lg border border-gray-200 p-6"
                >
                    <form-control-group-row>
                        <form-field-array-error
                            :name="`line_items[${key}].chart_of_account_id`"
                            #="{ errorMessage }"
                        >
                            <div class="flex w-full flex-col gap-2">
                                <form-detail-chart-of-account
                                    v-model="value.chart_of_account_id"
                                    :error-message="errorMessage"
                                    :input-display="
                                        getChartOfAccountName(
                                            value.chart_of_account
                                        )
                                    "
                                    :label="
                                        $t(
                                            'purchasesExpense.create.expenseAccount.label'
                                        )
                                    "
                                    :placeholder="
                                        $t(
                                            'purchasesExpense.create.expenseAccount.placeholder'
                                        )
                                    "
                                    type="expense"
                                    :include-modal="false"
                                    @on-close="
                                        resetFieldError(
                                            `line_items[${key}].chart_of_account_id`
                                        )
                                    "
                                    @open-modal="onOpenAddModal(value)"
                                />

                                <purchases-expense-suggest-coa
                                    v-if="!value?.chart_of_account_id"
                                    :field="value"
                                    :key-field="idx"
                                    @replace-coa="onReplaceCoa"
                                />
                            </div>
                        </form-field-array-error>

                        <form-field-array-error
                            :name="`line_items[${key}].description`"
                            #="{ errorMessage }"
                        >
                            <form-text-input
                                v-model="value.description"
                                :highlight="
                                    lineItemsHighlight?.[idx]?.description
                                "
                                hide-message
                                :label="
                                    $t(
                                        'purchasesExpense.create.description.label'
                                    )
                                "
                                :placeholder="
                                    $t(
                                        'purchasesExpense.create.description.placeholder'
                                    )
                                "
                                :error-message="errorMessage"
                                @blur="
                                    resetFieldError(
                                        `line_items[${key}].description`
                                    )
                                "
                            />
                        </form-field-array-error>

                        <form-field-array-error
                            :name="`line_items[${key}].amount`"
                            #="{ errorMessage }"
                        >
                            <form-number-input
                                v-model="value.amount"
                                :highlight="lineItemsHighlight?.[idx]?.amount"
                                hide-message
                                :label="
                                    $t('purchasesExpense.create.amount.label')
                                "
                                :placeholder="
                                    $t(
                                        'purchasesExpense.create.amount.placeholder'
                                    )
                                "
                                :error-message="errorMessage"
                                input-class="pr-16"
                                :precision="2"
                                @blur="
                                    resetFieldError(`line_items[${key}].amount`)
                                "
                            >
                                <template #right>
                                    <div
                                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center border-l border-gray-300 px-3"
                                    >
                                        {{ iso }}
                                    </div>
                                </template>
                            </form-number-input>
                        </form-field-array-error>

                        <form-field-array-error
                            :name="`line_items[${key}].tax_rule_id`"
                            #="{ errorMessage }"
                        >
                            <form-single-select
                                v-model="value.tax_rule_id"
                                :highlight="
                                    lineItemsHighlight?.[idx]?.tax_rule_id
                                "
                                :error-message="errorMessage"
                                hide-message
                                :label="$t('purchasesExpense.create.tax.label')"
                                :placeholder="
                                    $t(
                                        'purchasesExpense.create.tax.placeholder'
                                    )
                                "
                                :options="taxRules"
                                option-value="id"
                                :option-label="customTaxLabel"
                                @on-close="
                                    resetFieldError(
                                        `line_items[${key}].tax_rule_id`
                                    )
                                "
                            />
                        </form-field-array-error>
                    </form-control-group-row>
                </form-control-group>
            </template>
        </form-field-array>

        <bookeeping-accounts-add-modal
            :show="showAddModal"
            @modal-close="onCloseAddModal"
            @on-finished="onFinished"
        />
    </div>
</template>

<script setup>
import { getChartOfAccountName } from '@tenant/utils/helper'

const props = defineProps({
    oldLineItems: {
        type: Array,
        default: null,
    },
})

const emitter = useEmitter()

const { taxRules } = useTaxRules(['purchases'])
const { remove, push, fields, replace } = useFieldArray('line_items')

const { value: itemize, setValue: setItemize } = useField('itemize')

const { value: lineItemsHighlight } = useHighlight('line_items')

const { canBackSingleView, toSingleView } = useItemize({
    replace,
    oldLineItems: toRef(props, 'oldLineItems'),
    fields,
})

const onRemoveItem = (idx) => {
    if (fields.value.length > 1) {
        remove(idx)
    }
}

const onAddItem = () => {
    push({
        chart_of_account_id: null,
        description: null,
        amount: null,
        tax_rule_id: null,
    })
}

const resetFieldError = (field) => {
    emitter.emit('reset-field-error', field)
}

const customTaxLabel = (option) => `${option.name} (${option.tax_rate})`

const backToSingleView = () => {
    setItemize(false)
    nextTick(() => {
        toSingleView()
    })
}

const onReplaceCoa = (keyField, coa) => {
    fields.value[keyField].value.chart_of_account_id = coa.id
    fields.value[keyField].value.chart_of_account_name =
        getChartOfAccountName(coa)
}

// Set currency based on selected contact currency
const contactCurrency = useFieldValue('contact_currency')
const { iso } = useExchangeRate(contactCurrency)

// COA MODAL
const { showAddModal, onCloseAddModal, onOpenAddModal, onFinished } =
    useCOAModal()
// END: COA MODAL
</script>
